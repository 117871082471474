import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  @Output() onSave = new EventEmitter<any>()
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  })
  public show: boolean = false;

  constructor(private service: CommonService, private toaster: ToastrService, private modalService: NgbModal,) { }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }
  tempStoreEmail = null;
  submitEmail(email) {
    if (!this.emailForm.valid) {
      Object.keys(this.emailForm.controls).forEach(key => {
        const control = this.emailForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      let formData = new FormData();
      formData.append('userName', email);
      formData.append('isForCustomer', 'false');
      this.tempStoreEmail = email;
      this.service.sendOTP(formData).subscribe(res => {
        if (res.success == true) {
          this.onSave.emit(email);
          this.emailForm.controls['email'].reset();
        } else {
          this.toaster.error(res.responseMessage, 'Error');
        }
      })
    }
  }
  closePopup() {
    this.modalService.dismissAll();
  }
}
