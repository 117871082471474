<form action="" [formGroup]="otpForm">
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <div class="container height-100 d-flex justify-content-center align-items-center">
                    <div class="position-relative text-center">
                        <h6>Please enter the one time password <br> to verify your account</h6>
                        <div> <span>A code has been sent to</span> <small>&nbsp; {{email}}</small> </div>
                        <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                            <input #otp1 formControlName="otp1" class="m-2 text-center form-control rounded" type="text"
                                id="first" maxlength="1" (keyup)="onDigitInput($event)"
                                (keypress)="numberOnly($event)" />
                            <input #otp2 formControlName="otp2" class="m-2 text-center form-control rounded" type="text"
                                id="second" maxlength="1" (keyup)="onDigitInput($event)"
                                (keypress)="numberOnly($event)" />
                            <input #otp3 formControlName="otp3" class="m-2 text-center form-control rounded" type="text"
                                id="third" maxlength="1" (keyup)="onDigitInput($event)"
                                (keypress)="numberOnly($event)" />
                            <input #otp4 formControlName="otp4" class="m-2 text-center form-control rounded" type="text"
                                id="fourth" maxlength="1" (keyup)="onDigitInput($event)"
                                (keypress)="numberOnly($event)" />
                            <input #otp5 formControlName="otp5" class="m-2 text-center form-control rounded" type="text"
                                id="fifth" maxlength="1" (keyup)="onDigitInput($event)"
                                (keypress)="numberOnly($event)" />
                            <input #otp6 formControlName="otp6" class="m-2 text-center form-control rounded" type="text"
                                id="sixth" maxlength="1" (keyup)="onDigitInput($event)"
                                (keypress)="numberOnly($event)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12" style="text-align: center;">
            <button (click)="verifyOTP(otp1.value,otp2.value,otp3.value,otp4.value,otp5.value,otp6.value)"
                class="btn btn-danger px-4 validate">Validate</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-center">
            <span class="text-center">
                <p *ngIf="!resendOtp">Wait for {{ display }} seconds to
                    resend OTP</p>
                <a href="javscript:void(0)" *ngIf="resendOtp" (click)="resendOTP()">
                    <p class="mb-0">Resend OTP</p>
                </a>
            </span>
        </div>
    </div>
</form>