import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { id } from '@swimlane/ngx-datatable';
import { ResponseData } from 'src/app/core/models/base-save-update-response-modal';
import { FilterTypeValue } from 'src/app/pages/filter-type-value/model/FilterTypeValue';
import { Category } from 'src/app/pages/category/model/Category';
import { DiscountType } from '../../pages/model/DiscountType'
import { Publisher } from 'src/app/pages/model/Publisher';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class CommonService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': JSON.parse(localStorage.getItem('authToken'))
    })
  };

  constructor(private http: HttpClient) { }

  public getStateByCountry(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'state/getStateByCountry/' + id
    );
  }


  public getStateList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'state/'
    );
  }

  public getCountryList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'country/'
    );
  }

  public getAuthorList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'author/'
    );
  }
  public getAllProduct(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'product/'
    );
  }
  public getPublisherList(): Observable<ResponseData<Publisher>> {
    return this.http.get<ResponseData<Publisher>>(
      environment.SERVER_URL + 'publisher/'
    );
  }
  public getStandardList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'standard/'
    );
  }
  public getFilterTypeValues(): Observable<ResponseData<FilterTypeValue>> {
    return this.http.get<ResponseData<FilterTypeValue>>(
      environment.SERVER_URL + 'filterTypeValue'
    );
  }

  public getFilterType(): Observable<ResponseData<FilterTypeValue>> {
    return this.http.get<ResponseData<FilterTypeValue>>(
      environment.SERVER_URL + 'filterType'
    );
  }
  public getCategories(): Observable<ResponseData<Category>> {
    return this.http.get<ResponseData<Category>>(
      environment.SERVER_URL + 'category/getLeastCategories'
    );
  }
  public getCategoryById(id): Observable<ResponseData<Category>> {
    return this.http.get<ResponseData<Category>>(
      environment.SERVER_URL + 'category/' + id
    );
  }
  public getDiscountTypes(): Observable<ResponseData<DiscountType>> {
    return this.http.get<ResponseData<DiscountType>>(
      environment.SERVER_URL + 'discountType'
    );
  }
  public getFilters(categoryId, productId): Observable<ResponseData<DiscountType>> {
    return this.http.get<ResponseData<DiscountType>>(
      environment.SERVER_URL + 'category/' + categoryId + "/" + productId
    );
  }
  public getOrderById(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/' + id
    );
  }

  public getAwbList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/getAWBList'
    );
  }
  public getFiltersCategory(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'category/' + id)
  }


  public updateOrderData(data): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SERVER_URL + 'order/update', data
    );
  }


  public sendOTP(email): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'sendmail', email
    );
  }
  public verifyOTP(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'verifyOtp', data
    );
  }
  public resetPassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'forgotPassword', data
    );
  }
  public changePassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'changePassword', data
    );
  }


  public getChildList(id): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SERVER_URL + 'category/checkForChild', id
    );
  }

  public downloadPartnerImage(id): Observable<Blob> {
    return this.http.get(environment.SERVER_URL + "fileDownload/getOurPartnersDtoImage/" + id, { responseType: 'blob' });
  }


  public getCustomerInvoiceDownload(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/invoiceGenerate/' + id)
  }
  public cancelOrder(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/cancelOrder/' + id
    );
  }

  public placeRefundRequest(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/placeRefundRequest/' + id
    );
  }

  public placeShipOrderRequest(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/placeShipOrderRequest/' + id
    );
  }

  public getCount() {
    return this.http.get<any>(environment.SERVER_URL + "dashboard/count");
  }

  public getCustomerLabelDownload(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/labelGenerate/' + id)
  }

  public getProfileImage(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getInstructorProfilePhoto/' + id
    );
  }
  public getCourseImage(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getCourseImage/' + id
    );
  }

  public getAllDiscountType(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'discountType/'
    );
  }
  public getAllInstructors(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'instructor/'
    );
  }

  public getAllChildCategory(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'category/childCategory'
    );
  }


  public getAllLanguage(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'language'
    );
  }


  public getCurriculumDocument(courseId, curriculumId, docId): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getCurriculumDoc/' + courseId + "/" + curriculumId + "/" + docId
    );
  }

  public getBlogImageOne(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getBlogImageOne/' + id
    );
  }

  public getBlogImageTwo(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getBlogImageTwo/' + id
    );
  }

  public getCategoryImage(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getCategoryImage/' + id
    );
  }



  public getAllCourseLevel(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'courseLevel'
    );
  }
  public getCustomerProfileImage(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'fileDownload/getCustomerImage/' + id
    );
  }

  public getAllCourse(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'course'
    );
  }


  public getAllBlogCategory(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'blogCategory'
    );
  }


  closeNgSelectOnContentScroll(list) {
    // Close all open ng-select dropdowns within the table
    list.forEach(ngSelect => {
      if (ngSelect.isOpen) {
        ngSelect.close();
      }
    });
  }
  onDeleteRecordFromSubFormArrayList(index, list) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this record?',
        // icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        // confirmButtonColor: '#34c38f',
        cancelButtonText: 'No',
        // cancelButtonColor: '#ff3d60',
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary', // Set the class for the confirm button
          cancelButton: 'btn btn-danger'    // Set the class for the cancel button
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          list.splice(index, 1);
          resolve({ success: true, list: list }); // Resolve with true and the updated list
        } else {
          resolve({ success: false, list: list }); // Resolve with false and the original list
        }
      }).catch(reject);
    });
  }

  onDeleteRecordFromSubFormList(index, list) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      // icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      // confirmButtonColor: '#34c38f',
      cancelButtonText: 'No',
      // cancelButtonColor: '#ff3d60',
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn btn-primary', // Set the class for the confirm button
        cancelButton: 'btn btn-danger'    // Set the class for the cancel button
      },
      buttonsStyling: false,

    }).then(result => {
      if (result.value) {
        return list.splice(index, 1);
      }
    });

  }



}
