import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../shared/services/firebase/auth.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class JwtIntercepterService implements HttpInterceptor {
  constructor(private authService: AuthService, private toastr: ToastrService,) { }
  /*
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.authService.AuthToken()){
      if(!req.headers.has('Authorization')){
        req = req.clone({
          setHeaders: { Authorization: JSON.parse(this.authService.AuthToken()) }
        });
      }
    }
    return next.handle(req);
  }
  */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser = localStorage.getItem('authUser');
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    if (authToken && currentUser) {
      // add Authorization header to request
      const authReq = request.clone({
        headers: new HttpHeaders({
          'Authorization': authToken,
        }),
      });
      // return next.handle(authReq);
      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0 || error.status === 503) {
            this.toastr.error("Server is temporarily unavailable. Please try again later.", 'Error', { timeOut: 5000 });
            this.authService.logout();
          }
          if (error.status === 401) {
            this.toastr.error("You are unauthorized. Please login again.", "Error", { timeOut: 5000 });
            this.authService.logout();
          }
          return throwError(error);
        })
      );
    } else {
      // if authToken is null, proceed with original request
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.toastr.error("You are unauthorized. Please login again.", "Error", { timeOut: 5000 });
            this.authService.logout();
          }
          return throwError(error);
        })
      );
    }
  }
}
