import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  @Output() onNewPwd = new EventEmitter<any>()
  @Input() email
  resetForm = new FormGroup({
    newPwd: new FormControl('', [Validators.required]),
    confirmNewPwd: new FormControl('', [Validators.required]),
  })
  constructor(private commonService: CommonService, private toaster: ToastrService) { }

  ngOnInit(): void {
  }
  isSamePwd: boolean = true;
  submitForm(newPwd, confirmPwd) {
    if (!this.resetForm.valid) {
      Object.keys(this.resetForm.controls).forEach(key => {
        const control = this.resetForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      if (newPwd != confirmPwd) {
        this.isSamePwd = false;
        return;
      }
      let formData = new FormData();
      formData.append('userName', this.email);
      formData.append('newPassword', newPwd);
      this.commonService.resetPassword(formData).subscribe(res => {
        if (res.success == true) {
          this.toaster.success('Password has been reset successfully', 'Success');
          this.onNewPwd.emit();
        } else {
          this.toaster.error(res.responseMessage, 'Error');
        }
      })
    }
  }
  get f() {
    return this.resetForm.controls;
  }

  reset() {
    this.isSamePwd = true;
  }
  newPwdType: string = 'password';
  confirmType: string = 'password';

  toggleNewPassword() {
    this.newPwdType = this.newPwdType === 'password' ? 'text' : 'password';
  }

  toggleConPassword() {
    this.confirmType = this.confirmType === 'password' ? 'text' : 'password';
  }
}
