<form [formGroup]="emailForm">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="col-form-label">Email</label>
        <input class="form-control" #email formControlName="email" type="email" placeholder="Enter your email">
        <div *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.required"
          class="text text-danger">
          This field is required
        </div>
        <div *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.pattern"
          class="text text-danger">
          Input invalid. enter valid email
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-12" style="text-align: center;">
      <button class="btn btn-primary" (click)="submitEmail(email.value)">Reset Password</button>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-12" style="text-align: center;">
      <p>
        Remember your password? <a routerLink="/auth/login" (click)="closePopup()">Login</a>
      </p>
    </div>
  </div>
</form>