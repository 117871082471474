<form [formGroup]="changePwdForm">
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="oldPassword">Old Password<span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="{{ oldPasswordType }}" #oldPassword formControlName="oldPassword" class="form-control"
                        id="oldPassword" name="oldPassword" spaceNotAllow appNoSpace
                        placeholder="Enter your old password" required>
                    <div class="input-group-append">
                        <span class="input-group-text" style="cursor: pointer;" (click)="toggleOldPassword()">
                            <i class="fa"
                                [ngClass]="oldPasswordType === 'password' ? 'fa toggle-password fa-eye' : 'fa toggle-password fa-eye-slash'"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="changePwdForm.controls.oldPassword.touched && changePwdForm.controls.oldPassword.errors?.required"
                    class="text text-danger">
                    Old Password is required.
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="form-group">
                <label for="newPassword">New Password<span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="{{ newPasswordType }}" #newPassword formControlName="newPassword" class="form-control"
                        id="newPassword" name="newPassword" spaceNotAllow appNoSpace
                        placeholder="Enter your new password" required>
                    <div class="input-group-append">
                        <span class="input-group-text" style="cursor: pointer;" (click)="toggleNewPassword()">
                            <i class="fa"
                                [ngClass]="newPasswordType === 'password' ? 'fa toggle-password fa-eye' : 'fa toggle-password fa-eye-slash'"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="changePwdForm.controls.newPassword.touched && changePwdForm.controls.newPassword.errors?.required"
                    class="text text-danger">
                    New Password is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12">
            <button class="btn btn-primary mr-4"
                (click)="submitForm(oldPassword.value,newPassword.value)">Change</button>
        </div>
    </div>
</form>