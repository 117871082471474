import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInterestRateFormat]'
})
export class InterestRateFormatDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove all non-numeric and non-decimal point characters, except the first decimal point if present
    inputValue = inputValue.replace(/[^0-9.]+/g, match => match === '.' ? '.' : '');

    // Split the value into integer and decimal parts
    const parts = inputValue.split('.');

    // Limit the integer part to 2 digits
    if (parts[0].length > 2) {
      parts[0] = parts[0].substr(0, 2);
    }

    // Limit the decimal part to 2 digits
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substr(0, 2);
    }

    // Reassemble the value with a single decimal point
    inputValue = parts.length > 1 ? parts[0] + '.' + parts[1] : parts[0];

    // Set the sanitized value back to the input element
    if (inputValue !== inputElement.value) {
      inputElement.value = inputValue;
      inputElement.dispatchEvent(new Event('input')); // Trigger input event if the value changed
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();

    // Get the pasted text
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');

    // Remove non-numeric and non-decimal point characters and set the cleaned value to the input
    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = pastedData.replace(/[^0-9.]/g, '');

    // Trigger the input event after pasting
    inputElement.dispatchEvent(new Event('input'));
  }
}
