import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Output() onPwdChange = new EventEmitter<any>()
  changePwdForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
  })
  constructor(private commonService: CommonService, private toaster: ToastrService, private authService: AuthService) { }

  ngOnInit(): void {
  }
  show: boolean = true
  showPassword() {

  }

  submitForm(oldPwd, newPwd) {
    if (!this.changePwdForm.valid) {
      Object.keys(this.changePwdForm.controls).forEach(key => {
        const control = this.changePwdForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      let obj = {
        "oldPassword": oldPwd,
        "newPassword": newPwd
      }
      this.commonService.changePassword(obj).subscribe(res => {
        console.log("res", res);
        if (res.success == true) {
          this.toaster.success("Password has been changed", 'Success');
          this.onPwdChange.emit();
          this.authService.logoutUser();
        } else {
          this.toaster.error(res.responseMessage, "Error");
        }
      })
    }
  }

  oldPasswordType: string = 'password';
  newPasswordType: string = 'password';

  toggleOldPassword() {
    this.oldPasswordType = this.oldPasswordType === 'password' ? 'text' : 'password';
  }

  toggleNewPassword() {
    this.newPasswordType = this.newPasswordType === 'password' ? 'text' : 'password';
  }
}
