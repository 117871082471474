<form [formGroup]="resetForm">
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="oldPassword">New Password<span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="{{ newPwdType }}" #newPwd formControlName="newPwd" class="form-control" id="newPwd"
                        name="newPwd" spaceNotAllow appNoSpace placeholder="Enter new password" required
                        (input)="reset()">
                    <div class="input-group-append">
                        <span class="input-group-text" style="cursor: pointer;" (click)="toggleNewPassword()">
                            <i class="fa"
                                [ngClass]="newPwdType === 'password' ? 'fa toggle-password fa-eye' : 'fa toggle-password fa-eye-slash'"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="f.newPwd.touched && f.newPwd.errors?.required" class="text text-danger">
                    This field is required
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="form-group">
                <label for="confirmNewPwd">Confirm Password<span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="{{ confirmType }}" #confirmNewPwd formControlName="confirmNewPwd" class="form-control"
                        id="confirmNewPwd" name="confirmNewPwd" spaceNotAllow appNoSpace (input)="reset()"
                        placeholder="Enter confirm password" required>
                    <div class="input-group-append">
                        <span class="input-group-text" style="cursor: pointer;" (click)="toggleConPassword()">
                            <i class="fa"
                                [ngClass]="confirmType === 'password' ? 'fa toggle-password fa-eye' : 'fa toggle-password fa-eye-slash'"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="f.confirmNewPwd.touched && f.confirmNewPwd.errors?.required" class="text text-danger">
                    This field is required
                </div>
            </div>
        </div>
    </div>
    <span *ngIf="isSamePwd == false" class="text-danger">New password and confirm password must be
        same.</span>
    <div class="row mt-2">
        <div class="col-md-12 text-left">
            <button class="btn btn-primary" (click)="submitForm(newPwd.value,confirmNewPwd.value)">Reset</button>
        </div>
    </div>
</form>