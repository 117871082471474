<div class="media profile-media">
  <img class="b-r-10 " height="37" width="37" src="assets/images/dashboard/user_blue.jfif" alt="">
  <div class="media-body "><span>Welcome Admin</span>
    <p class="mb-0 font-roboto  ">{{user.email}}<i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a href="javascript:void(0)" (click)="changePass(changePassword)">
      <app-feather-icons [icon]="'edit'"></app-feather-icons><span>Change Password</span>
    </a></li>
  <li><a href="javascript:void(0)" (click)="logout()">
      <app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Logout</span>
    </a></li>
</ul>

<ng-template #changePassword let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Change Password</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <app-reset-password (onPwdChange)="close()"></app-reset-password>
  </div>
</ng-template>