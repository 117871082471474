import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'category',
    loadChildren: () => import('../../pages/category/category.module').then(m => m.CategoryModule)
  },
  {
    path: 'course',
    loadChildren: () => import('../../pages/course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('../../pages/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'instructor',
    loadChildren: () => import('../../pages/instructor/instructor.module').then(m => m.InstructorModule)
  },
  {
    path: 'review',
    loadChildren: () => import('../../pages/review/review.module').then(m => m.ReviewModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('../../pages/blog/blog.module').then(m => m.BlogModule)
  },

  //old code

  {
    path: 'filters',
    loadChildren: () => import('../../pages/filter-type/filter-type.module').then(m => m.FilterTypeModule)
  },
  /*
  {
    path: 'payments',
    loadChildren: () => import('../../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  */

  {
    path: 'razerpay',
    loadChildren: () => import('../../pages/razerpay/razerpay.module').then(m => m.RazerpayModule)
  },
  {
    path: 'authors',
    loadChildren: () => import('../../pages/authors/authors.module').then(m => m.AuthorsModule)
  },
  {
    path: 'publisher',
    loadChildren: () => import('../../pages/publisher/publisher.module').then(m => m.PublisherModule)
  },
  {
    path: 'slider',
    loadChildren: () => import('../../pages/slider/slider.module').then(m => m.SliderModule)
  },
  {
    path: 'inquiry',
    loadChildren: () => import('../../pages/enquiry/enquiry.module').then(m => m.EnquiryModule)
  },

  {
    path: 'coupon',
    loadChildren: () => import('../../pages/coupan/coupan.module').then(m => m.CoupanModule)
  },

];
