import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SUCCESS } from 'dropzone';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  display: any;
  resendOtp: boolean = false;
  displayTimer: boolean = false;
  @Output() onVerify = new EventEmitter<any>()
  @Output() onSave = new EventEmitter<any>()
  @Input() email
  otpForm = new FormGroup({
    otp1: new FormControl('', [Validators.required]),
    otp2: new FormControl('', [Validators.required]),
    otp3: new FormControl('', [Validators.required]),
    otp4: new FormControl('', [Validators.required]),
    otp5: new FormControl('', [Validators.required]),
    otp6: new FormControl('', [Validators.required]),

  })
  constructor(private service: CommonService, private toaster: ToastrService) {
    this.start(1);
  }
  start(minute) {
    this.displayTimer = true;
    this.resendOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      if (statSec < 10) {
        textSec = '0' + statSec;
      } else {
        textSec = statSec;
      }
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        console.log('finished');
        clearInterval(timer);
        this.resendOtp = true;
        this.displayTimer = false;
      }
    }, 1000);
  }

  ngOnInit(): void {
  }
  verifyOTP(a, b, c, d, e, f) {
    if (!this.otpForm.valid) {
      Object.keys(this.otpForm.controls).forEach(key => {
        const control = this.otpForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      console.log(a + b + c + d + e + f);
      let formData = new FormData();
      formData.append('userName', this.email);
      formData.append('otp', a + b + c + d + e + f);
      console.log("f1", formData);
      this.service.verifyOTP(formData).subscribe(res => {
        if (res.success == true) {
          this.onVerify.emit();
        } else {
          this.toaster.error(res.responseMessage, 'Error');
        }
      })
    }
  }

  onDigitInput(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    let element;
    if (event.code !== 'Backspace' && !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)))
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace' && !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)))
      element = event.srcElement.previousElementSibling;

    if (element == null)
      return;
    else
      element.focus();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }


  resendOTP() {
    if (this.email) {
      let formData = new FormData();
      formData.append('userName', this.email);
      this.service.sendOTP(formData).subscribe(res => {
        console.log("res", res);
        if (res.success == true) {
          this.toaster.success('OTP sent Successfully', 'Success');
          this.displayTimer = true;
          this.start(1);
        } else {
          this.toaster.error(res.responseMessage, 'Error');
        }
      })
    }
  }

}
