import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;

  constructor(public authService: AuthService, private fb: FormBuilder,
    private router: Router, private toastr: ToastrService, private commonService: CommonService, private modelService: NgbModal) {
    this.loginForm = this.fb.group({
      // email: ['test@gmail.com', [Validators.required, Validators.email]],
      // password: ['test123', Validators.required]
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  login() {
    if (!this.loginForm.valid) {
      Object.keys(this.loginForm.controls).forEach(key => {
        const control = this.loginForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    }
    else {
      this.loginUser()
    }
  }
  loginUser() {
    this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']).pipe(first()).subscribe(
      res => {
        if (res) {
          if (res.success == false) {
            this.toastr?.error(res.responseMessage, "Error");
          } else {
            if (res.data) {
              this.router.navigate(['dashboard/default']);
            }
          }
        }
      },
      error => {
        console.log("something")
      });
  }
  get f() {
    return this.loginForm.controls;
  }
  forgotPass(template) {
    this.modelService.open(template, { size: 'ms' })
  }
  tempStoreEmail = null;
  onSaveFun(e, tmpReff) {
    this.tempStoreEmail = e;
    this.modelService.dismissAll();
    this.modelService.open(tmpReff, { size: 'ms' });
  }
  onVerifyFun(newPasswordTmp) {
    this.modelService.dismissAll();
    this.modelService.open(newPasswordTmp, { size: 'ms' });
  }
  onNewPwdFun() {
    this.modelService.dismissAll();
  }
}
