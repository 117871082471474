<div class="container-fluid">
    <div class="row">
        <div class="col-md-6" style="margin-top:35px">
            <h4>{{title | translate }}</h4>
        </div>
        <ng-container *ngIf="settings?.isAddButton === true">
            <div class="col-md-6" style="margin-top:35px">
                <div style="text-align: right;float: right;">
                    <button type="button" class="btn btn-outline-primary" style="font-weight:bold;"
                        (click)="openForm()">{{'Add New' | translate}}</button>
                </div>
            </div>
        </ng-container>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="ml-4 mr-4 mt-5">
                    <div class="float-left">
                        <select (change)="onPageSizeChange($event)" class="form-control" style="font-size: 13px;">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="70">70</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="float-right">
                        <form action="">
                            <input type="text" #search [(ngModel)]="searchKeyword"
                                placeholder="{{ 'Search...'| translate}}"
                                style="font-size: 13px; width: 70%;float: left;" class="form-control">
                            <button class="btn btn-sm btn-primary float-right" style="width: 28%;padding:6px;"
                                type="submit" (click)="onFilter(search)">Search</button>
                        </form>
                    </div>
                </div>
                <div class="card-body custom-datatable">
                    <div class="d-none">
                        <div class="form-group">
                            <select class="form-control" name="state" id="maxRows" style="width:70px">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="70">70</option>
                                <option value="100">100</option>
                                <option value="5000">Show ALL Rows</option>
                            </select>
                        </div>

                    </div>
                    <div class="table-responsive scroll-inner" style="overflow-x: auto;">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let column of settings?.columns">
                                        <ng-container>
                                            <th style="font-size:14px;"
                                                (click)="column.isFilterAllow?onSort(column.rowKey):false"
                                                style="cursor:pointer; user-select: none;">
                                                {{column.default | translate}}
                                                <span *ngIf="column.isFilterAllow ===true"><i class="fa fa-fw fa-sort"
                                                        style="font-size:10px"></i>
                                                </span>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let data of dataSourceList;let i = index">
                                    <tr>
                                        <ng-container>
                                            <td>{{((pagination.page-1) * pagination.pageSize) + (i+1)}} </td>
                                        </ng-container>

                                        <ng-container *ngFor="let col of dataKey">
                                            <ng-container *ngFor="let key of ObjetKyes(data)">
                                                <ng-container *ngIf="key === col.column">
                                                    <ng-container *ngIf="key !=='index'">
                                                        <td>
                                                            <ng-container *ngIf="col.columnValue !==null">
                                                                <ng-container [ngTemplateOutlet]="col.columnValue"
                                                                    [ngTemplateOutletContext]="{data:data,indexOfelement:i}">
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.columnValue ===null">
                                                                <ng-container *ngIf="data[key]!== null">
                                                                    <span>{{data[key]}}</span>
                                                                </ng-container>
                                                                <ng-container *ngIf="data[key] === null">
                                                                    <span>-</span>
                                                                </ng-container>
                                                            </ng-container>
                                                        </td>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                    <ng-container *ngIf="settings.customControl">
                                        <ng-container [ngTemplateOutlet]="settings.customControl"
                                            [ngTemplateOutletContext]="{data:data,indexOfelement:i}">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <!-- implement custom Value -->

                                <!-- End of custom value -->
                            </tbody>
                        </table>
                    </div>
                    <ng-container *ngIf="settings?.pageTotalLength !==0; else noDataMessage">
                        <div class="row justify-content-md-between align-items-md-center mt-2">
                            <div class="col-7 text-md-left float-md-left">
                                <br>
                                {{ 'Showing'|translate}} {{((pagination.page-1) * pagination.pageSize) + (1)}}
                                {{'to'|translate}}
                                {{((pagination.page-1) * pagination.pageSize) + (1)-1 + (settings.totalElements)}}
                                {{'of'|translate}} {{settings.pageTotalLength}}
                            </div>
                            <div class="col-5">
                                <div class="text-md-right float-md-right mt-2">
                                    <ngb-pagination class="d-flex justify-content-end"
                                        (pageChange)="onPageChange($event)" [rotate]="true" [ellipses]="false"
                                        [boundaryLinks]="true" [maxSize]="3" [collectionSize]="settings.pageTotalLength"
                                        [(page)]="page">
                                    </ngb-pagination>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noDataMessage>
    <div class="row justify-content-md-between align-items-md-center mt-3">
        <div class="col-lg-12" style="text-align: center;">
            <p>Data Not Available</p>
        </div>
    </div>
</ng-template>