import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appSpaceNotAllowed]'
})
export class SpaceNotAllowedDirective {
  @HostBinding('autocomplete') public autocomplete;
  constructor(private el: ElementRef<HTMLInputElement>) {
    this.autocomplete = 'off';
  }

  @HostListener("keypress", ['$event'])
  public spaceNotAllow(event) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }
}
